import * as d3 from "d3";
import React,{ useRef, useState, useEffect, useLayoutEffect, useCallback } from "react";
import axios from "axios";
import { useRecoilState } from "recoil";
import { simuParameters, baseUrl } from "../App";
import { Loader } from "./svgs";
import {calculateDimensions} from "../utils/shapeutils"
import '../styles/drawing.css'  
import '../styles/tooltip.css'  

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function Heatmap() {
  const parent = useRef();
  const svgRef = useRef(null);
  const chartGroupRef = useRef(null);
  const [loading, setloading] = useState(false);
  const [plotData, setplotData] = useState(null);
  const [simuParams] = useRecoilState(simuParameters);
  const [dims] = useWindowSize();


  const handleMouseOver = useCallback((event, d) => {
    const tooltip = d3.select("#hoverInfo");
    console.log(event)
    tooltip.style("visibility", "visible")
           .text(`${d3.format(".1f")(d.area)} µm²`)
           .style("top", `${event.layerY}px`) // Offset tooltip
           .style("left", `${event.layerX}px`);
    d3.select(event.target).attr("fill", "#3730a3");
  }, []);

  const handleMouseOut = useCallback(event => {
    const tooltip = d3.select("#hoverInfo");

    tooltip.style("visibility", "hidden");
    d3.select(event.target).attr("fill", "#4f46e5");
  }, []);

  
  function drawPlot(chartGroup, plotData, xScale, yScale){
    const scaledPolygons = plotData.polygons.map(polygon => ({
      ...polygon,
      scaledVertices: polygon.vertices.map(xy => [xScale(xy[0]), yScale(xy[1])])
    }));
  
    // Select the existing polygons or create a new placeholder if they don't exist
    const polygons = chartGroup.selectAll("polygon")
      .data(scaledPolygons);
  
    // Exit and remove any polygons that no longer match the data
    polygons.exit().remove();
  
    // Enter new polygons
    polygons.enter()
      .append("polygon")
      .merge(polygons) // Merge enter and update selections
      .attr("clip-path", "url(#clip)")
      .attr("fill", "#4f46e5")
      .attr("stroke", "#000000")
      .attr("points", d => d.vertices.map(xy => `${xScale(xy[0])},${yScale(xy[1])}`).join(" "))
      .on("mouseover", handleMouseOver)
      .on("mouseout",  handleMouseOut);
  };

  function initPlot(svgRef,parentRef) {
    const { width, height } = calculateDimensions(parentRef);
    const svg = d3.select(svgRef.current)
      .attr("width",width)
      .attr("height",height);
      const chartGroup = svg.append("g");
      // .attr("transform", `translate(${marginLeft}, ${marginTop})`);
  
      chartGroup.append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", width)
        .attr("height", height)
        .attr("fill", "#fff")
        .attr("stroke", "#000")
        .attr("clip-path", "url(#clip)");
  
      chartGroup.append("clipPath")
        .attr("id", "clip")
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", width)
        .attr("height", height)
        .attr("rx", 30)
        .attr("ry", 30);
  
      return chartGroup;
  }

  const params = new URLSearchParams([
    ["mcx", simuParams.mesh.mcx],
    ["mcy", simuParams.mesh.mcy],
    ["dx", simuParams.mesh.dx],
 //   ["dy", simuParams.mesh.dy],
    ["kx", simuParams.mesh.kx],
    ["ky", simuParams.mesh.ky],
    ["theta", simuParams.mesh.theta],
    ["phi", simuParams.mesh.phi],
    ["xsize", simuParams.mesh.size],
  ]);
  //baseUrl +
  //const url = "https://sdt.ise.fraunhofer.de/api/single_mesh/";
  const url = baseUrl +"/single_mesh/";

  const fetchPlotData = async () => {
    setloading(true);
    try{
    const response = await axios.get(url, {
      params,
    });
    console.log("new", response.data);
    setplotData(response.data);
  }catch(error){
    console.log(error.response);
  }
    setloading(false);
  };

  useEffect(() => {
    console.log(simuParams);
    if (!loading)
      fetchPlotData();
  }, [simuParams.mesh]);

  useEffect(() => {
    if (plotData) {
      const { width, height } = calculateDimensions(parent);

      setloading(true);
      const xScale = d3.scaleLinear()
      .range([0, width])
      .domain([plotData.xDomain[0], plotData.xDomain[1]]);

    const yScale = d3.scaleLinear()
      .range([height, 0])
      .domain([plotData.yDomain[0], plotData.yDomain[1]]);
      drawPlot(chartGroupRef.current, plotData, xScale, yScale);;
      setloading(false);
    }
  }, [plotData, dims]);

  useEffect(() => {
    chartGroupRef.current = initPlot(svgRef,parent);
  }, []);


  return (
    <div ref={parent} className="drawing col-span-2">
      <svg ref={svgRef}></svg>
      <div
        id="hoverInfo"
        className="tooltip"
      >
      </div> 
      {loading ? ( 
     <div className="absolute inset-0 flex justify-center items-center"> 
      <div className="w-16">
        <Loader/>
      </div>  
    </div>
       ) : null} 
    </div>
  );
}

export default Heatmap;
