export const customKeys = {
    //for stats
        total_open_area: "Total open area",
        max_open_area: "Biggest open area",
        min_open_area: "Smallest open area",
        mean_open_area: "Mean open area size",
        std_open_area: "Standard deviation (Open Areas)",
        median_open_area: "Median open area size",
        percentile_5: <>Areas<sub>5%</sub></>,
        percentile_25: <>Areas<sub>25%</sub></>,
        percentile_75: <>Areas<sub>75%</sub></>,
        percentile_95: <>Areas<sub>95%</sub></>,
        len_open_areas: "Number of open areas",
        mean_open_ratio: "Mean ratio (Open Areas)",
        std_open_ratio: "Standard deviation ratio (Open Areas)",
        single_mesh_area_with_wire: "Single mesh unit area with wire",
        total_full_cells: "Total full cells",
        total_partial_cells: "Total partial cells",
        mean_partial_cell_area: "Mean partial cells area",
        std_partial_cell_area: "Standard deviation (Partial Cells)",
        //for config
        angle_degrees: "Mesh Angle",
        layout_shift_x: "Layout Shift (X)",
        layout_shift_y: "Layout Shift (Y)",
        mesh_count_per_inch_x: "Meshcount (Weft)",
        mesh_count_per_inch_y: "Meshcount (Warp)",
        wire_diameter_x: "Wire diameter (Weft)",
        wire_diameter_y: "Wire diameter (Warp)",
        mesh_size_um: "Mesh Size"
};

const units = {
    angle_degrees: " °",
    layout_shift_x: " µm",
    layout_shift_y: " µm",
    mesh_count_per_inch_x: <>1&#x2044;inch</>,
    mesh_count_per_inch_y: <>1&#x2044;inch</>,
    wire_diameter_x: " µm",
    wire_diameter_y: " µm",
    total_open_area: " µm²",
    max_open_area: " µm²",
    min_open_area: " µm²",
    mean_open_area: " µm²",
    std_open_area: " µm²",
    mean_open_ratio: "",
    std_open_ratio: "",
    single_mesh_area_with_wire: " µm²",

    median_open_area: " µm²",
    percentile_5: " µm²",
    percentile_25: " µm²",
    percentile_75: " µm²",
    percentile_95: " µm²",
    mesh_size_um: " µm²",
};

const noDecimalKeys = [
    "len_open_areas",
    "mesh_count_per_inch_x",
    "mesh_count_per_inch_y",
    "wire_diameter_x",
    "wire_diameter_y",
];

export function formatResultKey(key) {
    const displayKey = customKeys[key] || key.replace("_", " ");
    return displayKey;
}

export function formatResultValue(key,value){

    const formattedValue = noDecimalKeys.includes(key)
        ? Number(value).toFixed(0)
        : Number(value).toFixed(1);

    return formattedValue;
}
export function formatResultUnit(key){
    const unit = units[key] || "";
    return unit;
}