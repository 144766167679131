import React, { useEffect, useState } from "react";
import { RecoilRoot, atom } from "recoil";
import Main from "./components/Main";
import Login from "./components/auth/login";
import Signup from "./components/auth/signup";
import NotFoundPage from "./components/NotFoundPage";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import axios from "axios";

export const baseUrl = "/api";

//for testing react stuff in local
//export const baseUrl = "https://sdt.ise.fraunhofer.de/api";
//export const baseUrl = "http://localhost:8000/api";


export const globalSimuResults = atom({
  key: "res",
  default: null,
});

export const simuParameters = atom({
  key: "simu",
  default: {
    mesh: {
    //  size: 10000,
  //    mc: 380,
      mcx: 380,
      mcy: 380,
    //  d: 14,
      dx: 14,
    //  dy: 14,
      //k: 0,
      kx: 0,
      ky: 0,
      theta: 90,
      phi: 22.5,
    },
    //mask: null,
  },
});

export const sweepIterationsState = atom({
  key: "sweepIterationsState",
  default: 100, // Standardwert
});

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };

  // Überprüfe den Token beim Laden der Anwendung
  useEffect(() => {
    const token = localStorage.getItem("token");
    console.log("Retrieved token:", token);

    if (token) {
      // validate token
      axios
        .get("/users/me", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          console.log("Token validation response:", response);

          if (response.status === 200) {
            setIsLoggedIn(true);
          } else {
            localStorage.removeItem("token");
          }
        })
        .catch((error) => {
          console.log("Token validation error:", error);
          localStorage.removeItem("token");
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <RecoilRoot>
      <Router>
        <Routes>
          <Route
            path="/login"
            element={
              <Login
                onLoginSuccess={handleLoginSuccess}
                isLoggedIn={isLoggedIn}
              />
            }
          />
          <Route path="/signup" element={<Signup />} />
          <Route
            path="/"
            element={
              isLoggedIn ? (
                <Main
                  isLoggedIn={isLoggedIn}
                  onLoginSuccess={handleLoginSuccess}
                />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route path="*" element={<NotFoundPage />} />{" "}
          {/* Fallback route for 404 */}
        </Routes>
      </Router>
    </RecoilRoot>
  );
}

export default App;
