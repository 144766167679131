import { useState } from "react";
import axios from "axios";
import Signup from "./signup";
import { useNavigate } from "react-router-dom";
import { FOOTER_TEXT, VERSION_NUM } from "../svgs";
import { baseUrl} from "../../App";

function Login({ onLoginSuccess, isLoggedIn }) {
    const initialValues = {
        username: "",
        password: "",        
    };

    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [loginError, setLoginError] = useState(""); // Usestate of login error
    const [loginWarning, setLoginWarning] = useState(""); //Usestate for login warning
    const navigate = useNavigate();

    // State for signup visibility  
    const [showSignup, setShowSignup] = useState(false); // State for signup visibility
    const handleSignupClick = () => {
      setShowSignup(true); // Set showSignup to true on signup button click
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validate(formValues);
        setFormErrors(errors);
        setIsSubmit(true);
        
        if (Object.keys(errors).length === 0) {
            const url = baseUrl +"/post_login/";
            const formData = {
                username: formValues.username,
                password: formValues.password
            };
            try {
                const response = await axios.post(url, formData);
                console.log("Login response:", response); // Konsolenausgabe zur Überprüfung
        
                if (response.status === 200) {
                    const token = response.data.access_token;
                    localStorage.setItem('token', token);
                    onLoginSuccess();
                    navigate("/");
                } else {
                    setLoginError("Invalid username or password.");
                    console.error("Error: ", response.data.message);
                }
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    setLoginWarning("User-Account restricted.");
                } else if (error.response && error.response.status === 401) {
                    setLoginError("Invalid username or password.");
                } else {
                    setLoginError("An unexpected error occurred.");
                }
                console.error("Login failed: ", error);
            }
        }
    };

    const validate = (values) => {
        const errors = {};
        if (!values.username) {
            errors.username = "Username is required!";
        }
        if (!values.password) {
            errors.password = "Password is required";
        }
        return errors;
    };

    const TopBar = () => {
        return (
            <div className="bg-[#179C7D] p-4 text-center fixed top-0 left-0 right-0 z-10">
                <img src="/14_FhG_white.png" alt="Logo" className="mx-auto w-56 h-auto" />
            </div>
        );
    };
    
    const [showPatchnotes, setShowPatchnotes] = useState(false); // State for patchnotes visibilit

    const handlePatchnotesClick = () => {
        setShowPatchnotes(!showPatchnotes);
    };

    const PatchnotesPopup = () => (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-xl font-bold mb-4">Patchnotes {VERSION_NUM}</h2>
                <ul className="list-disc pl-5 space-y-2">
                    <li>Initial release</li>
                    <li>Alpha Version</li>
                    {"Caution! Could contain bugs, please report any abnormalities."}
                </ul>
                <div className="mt-4"></div>
                <h2 className="text-xl font-bold mb-4">Known bugs {VERSION_NUM}</h2>
                <ul className="list-disc pl-5 space-y-2">
                    <li>Login text disappearing during typing after registration process.</li>
                    <li>Results section not yet cleaned up + units missing.</li>
                    <li>Amount of displayed mesh units wrong.</li>
                    <li>MC not the same for weft and warp.</li>
                </ul>
                <button 
                    onClick={handlePatchnotesClick} 
                    className="mt-4 bg-[#179C7D] hover:bg-[#148C70] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Close
                </button>
            </div>
        </div>
    );

    return (
        <>
            {showSignup ? (
                <Signup />
            ) : (
                <>
                    <TopBar />
                    <div className="flex justify-center items-center h-screen bg-gray-200">
                        <div className="bg-white p-8 rounded-lg shadow-lg relative">
                            <h2 className="text-2xl font-bold mb-8 text-gray-800">Login</h2>
                            <p className="text-red-500 mb-8">Note: The service may be unavailable due to maintenance work.</p>

                            <button 
                                onClick={handlePatchnotesClick} 
                                className="absolute top-2 right-2 bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-1 px-3 rounded focus:outline-none focus:shadow-outline"
                            >
                                Patchnotes
                            </button>
                            
                            {loginWarning && (
                                <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative mb-4 text-sm flex items-center" role="alert">
                                    <strong className="font-bold mr-1">Warning: </strong>
                                    <span className="block sm:inline">{loginWarning}</span>
                                    <span className="ml-auto" onClick={() => setLoginWarning("")}>
                                        <svg className="fill-current h-6 w-6 text-yellow-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 5.652a.5.5 0 00-.707 0L10 9.293 6.36 5.652a.5.5 0 10-.707.707L9.293 10l-3.64 3.64a.5.5 0 00.707.707L10 10.707l3.64 3.64a.5.5 0 00.707-.707L10.707 10l3.64-3.64a.5.5 0 000-.707z"/></svg>
                                    </span>
                                </div>
                            )}
    
                            {loginError && (
                                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 text-sm flex items-center" role="alert">
                                    <strong className="font-bold mr-1">Error: </strong>
                                    <span className="block sm:inline">{loginError}</span>
                                    <span className="ml-auto" onClick={() => setLoginError("")}>
                                        <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 5.652a.5.5 0 00-.707 0L10 9.293 6.36 5.652a.5.5 0 10-.707.707L9.293 10l-3.64 3.64a.5.5 0 00.707.707L10 10.707l3.64 3.64a.5.5 0 00.707-.707L10.707 10l3.64-3.64a.5.5 0 000-.707z"/></svg>
                                    </span>
                                </div>
                            )}
                            
                            {isSubmit && Object.keys(formErrors).length > 0 && (
                                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 text-sm flex items-center" role="alert">
                                    <strong className="font-bold mr-1">Error: </strong>
                                    <span className="block sm:inline">Please fill in all required fields.</span>
                                    <span className="ml-auto" onClick={() => setIsSubmit(false)}>
                                        <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 5.652a.5.5 0 00-.707 0L10 9.293 6.36 5.652a.5.5 0 10-.707.707L9.293 10l-3.64 3.64a.5.5 0 00.707.707L10 10.707l3.64 3.64a.5.5 0 00.707-.707L10.707 10l3.64-3.64a.5.5 0 000-.707z"/></svg>
                                    </span>
                                </div>
                            )}
    
                            <form onSubmit={handleSubmit}>
                                <div className="mb-4">
                                    <label htmlFor="username" className="block text-sm font-medium text-gray-700">Username</label>
                                    <input type="text" id="username" name="username" value={formValues.username} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                </div>
                                <p className="text-red-500 text-xs italic">{formErrors.username}</p>
                                <div className="mb-4">
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                                    <input type="password" id="password" name="password" value={formValues.password} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                </div>
                                <p className="text-red-500 text-xs italic">{formErrors.password}</p>
                                <button type="submit" className="w-full bg-[#179C7D] hover:bg-[#148C70] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Login</button>
                                {/* <label className="block text-sm font-medium text-gray-700 mt-4 text-center">Don't have an account?</label>
                                <button type="button" onClick={handleSignupClick} className="w-full bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded mt-4 focus:outline-none focus:shadow-outline">Signup</button> */}
                            </form>
                            <div className="fixed bottom-0 right-2 text-black-700 text-sm">
                                {FOOTER_TEXT}
                            </div>
                            <div className="fixed bottom-0 left-2 text-black-700 text-sm">
                                {VERSION_NUM}
                            </div>
                        </div> 
                    </div>
                </>
            )}
            {showPatchnotes && <PatchnotesPopup />}
        </>
    );
}

export default Login;