import React, {useState, useEffect} from 'react';
import axios from 'axios';

import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper
} from '@mui/material';
import {baseUrl} from "../../App";
import "../../styles/databasetable.css"
import {Loader} from "../svgs";
import {globalSimuResults} from "../../App";
import {useRecoilState} from "recoil";

const DatabaseTable = (probs) => {
    const [simResults] = useRecoilState(globalSimuResults);
    const [bestConfigId, setBestConfigId] = useState(-1)
    const [page, setPage] = useState(0);
    const [rowsPerPage,] = useState(5);
    const database_url = baseUrl + "/database/"
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(database_url);
                const result = await response.data;
                setData(result);
                const ids = result.map(row => row.id)
                probs.setManualValues(
                    prevState => ({
                        ...prevState,
                        "ids": ids
                    }));
                setLoading(false)

            } catch (error) {
            }
        };
        fetchData();

    }, []);


    useEffect(() => {
        if (simResults?.config && simResults?.config?.id) {
            setBestConfigId(simResults.config.id)
            const rowIndex = data.findIndex(row => row.id === simResults.config.id)
            console.log(bestConfigId)
            console.log(rowIndex)
            const newPage = Math.floor(rowIndex / rowsPerPage)
            setPage(newPage)
        }
    }, [simResults]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleSelectAll = (event) => {
        const newSelected = event.target.checked ? data.map(row => row.id) : [];
        probs.setManualValues(
            prevState => ({
                ...prevState, "ids": newSelected
            }));
    };
    const handleSelectRow = (id) => {
        const newSelected = [...probs.manualValues['ids']];
        if (newSelected.includes(id)) {
            newSelected.splice(newSelected.indexOf(id), 1); // Remove if already selected
        } else {
            newSelected.push(id); // Add if not selected
        }
        probs.setManualValues(
            prevState => ({
                ...prevState,
                "ids": newSelected
            })
        );
    };

    return (

        <Paper>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={probs.manualValues["ids"].length == data.length}
                                    indeterminate={data.length > probs.manualValues["ids"].length && probs.manualValues["ids"].length > 0}
                                    onChange={handleSelectAll}
                                    color='secondary'/>
                            </TableCell>
                            <TableCell className='MuiTableCell-header'>Id</TableCell>
                            <TableCell className='MuiTableCell-header'>MeshCount Weft</TableCell>
                            <TableCell className='MuiTableCell-header'>MeshCount Wrap</TableCell>
                            <TableCell className='MuiTableCell-header'>Wire Diameter</TableCell>
                            <TableCell className='MuiTableCell-header'>Screen Angle</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (<TableRow key="loading">
                            <TableCell></TableCell>
                            <TableCell>
                            </TableCell>
                            <TableCell>
                                <Loader width="20px" fullSize={false}/>
                            </TableCell>
                            <TableCell>
                                <Loader width="20px" fullSize={false}/>
                            </TableCell>
                            <TableCell>
                                <Loader width="20px" fullSize={false}/>
                            </TableCell>
                            <TableCell>
                                <Loader width="20px" fullSize={false}/>
                            </TableCell>
                        </TableRow>) : (
                            data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => (
                                    <TableRow key={row.id}
                                              className={row.id === bestConfigId ? "best-id-row" : ""}
                                    >
                                        <TableCell padding="checkbox"
                                        >
                                            <Checkbox
                                                checked={probs.manualValues['ids'].includes(row.id)}
                                                onChange={() => handleSelectRow(row.id)}
                                                color={row.id === bestConfigId ? "secondary" : "default"}

                                            />
                                        </TableCell>
                                        <TableCell
                                            className={row.id === bestConfigId ? "MuiTableCell-header" : ""}
                                        >{row.id}</TableCell>
                                        <TableCell
                                            className={row.id === bestConfigId ? "MuiTableCell-header" : ""}
                                        >{row.mesh_count_per_inch_x}</TableCell>
                                        <TableCell
                                            className={row.id === bestConfigId ? "MuiTableCell-header" : ""}
                                        >{row.mesh_count_per_inch_y}</TableCell>
                                        <TableCell
                                            className={row.id === bestConfigId ? "MuiTableCell-header" : ""}
                                        >{row.wire_diameter_x}</TableCell>
                                        <TableCell
                                            className={row.id === bestConfigId ? "MuiTableCell-header" : ""}
                                        >{probs.manualValues['phi']}</TableCell>
                                    </TableRow>

                                )))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                //   onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default DatabaseTable;