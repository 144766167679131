import React, { useState } from 'react';
import axios from 'axios';
import { CircularProgress, Modal } from '@mui/material';
import { globalSimuResults, baseUrl } from "../../App";
import { useRecoilState } from "recoil";
import '../../styles/button.css'; // Import the CSS file
import {Loader} from '../svgs'
const FileProcessor = ({meshConfig,imageOptions}) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [downloadLink, setDownloadLink] = useState('');

  const [open, setOpen] = useState(false);
  const [simResults] = useRecoilState(globalSimuResults);
  const procces_url = baseUrl + "/process-file/"
  const status_url = baseUrl + "/status/"

  const handleClick = async () => {


    if (!simResults) {
      return;
    }

    setLoading(true);
    setStatus('');
    setOpen(true);
    //             const response =     await axios.get(procces_url+ simResults.guidTask+"/")

    const fileSaveUrl = baseUrl + "/file_save/";
    let layoutInfo = { layers: null, fileUrl: null };
    if (imageOptions.fileType === "dxf" && imageOptions.rawImage
      && imageOptions.layers.length > 0)
      layoutInfo.layers = imageOptions.layers;
    const saveFileData = await saveFileFormData(imageOptions);
      const fileResponse = await axios.post(fileSaveUrl, saveFileData)
      const filedata = await fileResponse.data;
      layoutInfo.fileUrl = filedata.fileUrl;
    const jsonData = {
      "layoutInfo":
        layoutInfo,
      "mesh": meshConfig,
    };
    const response =await axios.post(procces_url, jsonData);
    const jobId = response.data.job_id;
    setStatus(response.data.status);

    const checkStatus = setInterval(async () => {
      try {

        const statusResponse = await axios.get(status_url + jobId + "/");
        if (statusResponse.data.status === 'completed') {
          clearInterval(checkStatus);
          setStatus('Process is completed!');
          setLoading(false);
          const apiUrl = statusResponse.data.url;

          let fullUrl;
          if (baseUrl.startsWith('http')) {
            fullUrl = `${baseUrl}${apiUrl}`;
            console.log("url with http:" + fullUrl);

          } else {
            fullUrl = `${window.location.origin}${baseUrl}${apiUrl}`;
            console.log("url without http:" + fullUrl);

          }

          setDownloadLink(fullUrl)
        } else if (statusResponse.data.status === 'failed') {
          clearInterval(checkStatus);
          setStatus('Processing failed.');
          setLoading(false);
        }
        else {
          setStatus(statusResponse.data.status )
        }
      } catch (error) {
        console.error('Error processing file:', error);
        setStatus('Error processing file.');
        setLoading(false);
        clearInterval(checkStatus)
      }
    }, 10000);// Check status every 5 seconds


  };
  //TODO this method is duplicated from SimuSection
  const saveFileFormData = async (imageOptions) => {
    const formData = new FormData();
    if (imageOptions.fileType === "image" && imageOptions.rawImage?.startsWith("data:image/")) {
      const base64Image = imageOptions.rawImage.split(",")[1];
      const byteCharacters = atob(base64Image);
      const byteNumbers = new Array(byteCharacters.length)
        .fill(0)
        .map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/jpeg" });
      formData.append("file", blob, "image.jpg");
    } else if (imageOptions.fileType === "dxf" && imageOptions.rawImage && imageOptions.layers.length > 0) {
      const dxfBlob = new Blob([imageOptions.rawImage], { type: "application/dxf" });
      formData.append("file", dxfBlob, "file.dxf");
    } else {
      console.error("No valid file to upload or unsupported file type");
      return;
    }
    return formData;
  }


  const handleDownload = () => {
    setOpen(false); // Close the modal after download
    setDownloadLink(''); // Clear the download link
  };

  return (

    <>
      <button className='download-button w-full'
        onClick={handleClick}>View High Quality</button>
      <Modal open={open} onClose={handleDownload}>
        <div className="px-8 max-w-8xl mx-36 font-poppins font-bold"
          style={{
            display: 'flex', flexDirection: 'column',
            alignItems: 'center', padding: '20px', backgroundColor:
              'white', margin: 'auto', marginTop: '20%',
            width: '15%', borderRadius: '10px'
          }}>
          {loading ? (<Loader width="25%" fullSize={false}/>) : <></>}
          <p className='font-semibold mt-2'>{status}</p>
          {downloadLink ? (
            <a
              className="download-button "
              target="_blank" rel="noopener noreferrer"
              href={downloadLink} download onClick={handleDownload}>Download</a>
          ) : <></>}
        </div>
      </Modal>
    </>);
};

export default FileProcessor;