import React, {useRef, useEffect, useState} from "react";
import {useRecoilState} from "recoil";
import {globalSimuResults} from "../../App";
import MathJax from "react-mathjax";
import {Pie, Bar} from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels'
import 'chart.js/auto';
import FileProcessor from "./FileProcessor";
import ExcelExport from "./ExcelExport"
import {Slider} from "@mui/material";
import "../../styles/results.css"
import { formatResultKey,formatResultValue,customKeys,formatResultUnit } from "../../utils/resultsutils";
function Results({imageOptions}) {
    const [simResults] = useRecoilState(globalSimuResults);
    const infoDiv = useRef(null);
    const pieDiv = useRef(null);
    const barDiv = useRef(null);
    const [pieValues, setPieValues] = useState({under: 0, over: null})

    useEffect(() => {
        if (simResults && infoDiv.current && pieDiv.current && barDiv.current) {
            pieDiv.current.style.height = `${infoDiv.current.clientHeight}px`;
            barDiv.current.style.height = `${infoDiv.current.clientHeight}px`;
            infoDiv.current.parentNode.style.height = `${infoDiv.current.clientHeight}px`;
            handleSliderChange(null,0);
        }
    }, [simResults]);
    if (!simResults) {
        return <p>No results to display.</p>;
    }

    const {stats, config,open_areas} = simResults;

    const handleSliderChange = (event, newValue) => {
        const under = open_areas.filter(item => item <= newValue).length
        const over = open_areas.filter(item => item > newValue).length
        setPieValues({under: under, over: over})

    }


    const pieData = {
        labels: ["Under critical size","Over critical size"],
        datasets: [
            {
                data: [pieValues.under,pieValues.over],
                backgroundColor: ["#FFCE56","#C92F50FF"],
                hoverBackgroundColor: [ "#EBCE85FF","#FF6384"],
            },
        ],
    };

    const pieOptions = {
        plugins: {
            datalabels: {
                formatter: (value, context) => {
                    const total = context.chart.data.datasets[0].data.reduce((acc, val) => acc + val, 0);
                    return (value / total * 100).toFixed(2) + '%';
                },
                color: (context) => {
                    return context.dataset.backgroundColor[context.dataIndex] === "#FFCE56" ? "#000000" : "#FFFFFF";
                },
            },
            
        },
    };

    const barData = {
        labels: Object.keys(stats).slice(1, 5).map((element) => formatResultKey(element)),
        datasets: [
            {
                label: "Area sizes",
                data:  Object.values(stats).slice(1, 5).map(value => Math.log(value)),
                backgroundColor: ["#C92F50FF", "#36A2EB", "#FFCE56", "#1CC4C4FF"],
                borderColor: ["#FF6384", "#6DB7E9FF", "#EBCE85FF", "#6BE0E0FF"],
                borderWidth: 1,
            },
        ],
    };

    const barOptions = {
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: false,
                    text: "Area [µm²]",
                },
            },
            x: {
                title: {
                    display: false,
                    text: "Data Points",
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        //   const label = tooltipItem.dataset.label || "";
                        const value = tooltipItem.raw || 0;
                        return `${Number(value).toFixed(1)} µm²`;
                    },
                },
            },
        },
    };

    function sliderValueLabelFormat(value) {
       return `${value} µm²`;
    }

    return (
        <MathJax.Provider>
            <div className="flex flex-col w-full grid grid-cols-3 gap-6 mb-8 justify-center">
                <div>
                    <div ref={infoDiv}>
                        <h3 style={{fontWeight: "bold", textDecoration: "underline"}}>
                            Open Area Information:
                        </h3>
                        <ul>
                            {Object.entries(stats).map(([key, value]) => (
                                <li key={key}>
                                    <strong>{formatResultKey(key)}:</strong>{" "}
                                    {formatResultValue(key,value)} {formatResultUnit(key)}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div ref={pieDiv}>
                    <h3 style={{fontWeight: "bold", textDecoration: "underline"}}>
                        Critical Size:
                    </h3>
                    <Slider
                        onChange={handleSliderChange}
                        valueLabelDisplay="auto"
                        min={0}
                        max={Math.floor(Math.max(...open_areas)+1)}
                        valueLabelFormat={sliderValueLabelFormat}
                    ></Slider>
                    <Pie plugins={[ChartDataLabels]} data={pieData} options={pieOptions}/>
                </div>
                <div ref={barDiv}>
                    <h3
                        style={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                        }}
                    >
                        Open Area Distribution:
                    </h3>
                    <Bar data={barData} options={barOptions}/>
                </div>
            </div>
            <div className="flex flex-col w-full grid grid-cols-3 gap-6 mb-8 justify-center">
                <div><h3 style={{fontWeight: "bold", textDecoration: "underline"}}>
                    Optimized Mesh Parameters:
                </h3>
                    <ul>
                        {Object.entries(config).filter(([key]) => customKeys.hasOwnProperty(key)).map(([key, value]) => (
                            <li key={key}>
                                <strong>
                                {formatResultKey(key)}:</strong>{" "}
                                {formatResultValue(key,value)} {formatResultUnit(key)}
                            </li>
                        ))}
                    </ul>
                    <div >
                        <FileProcessor meshConfig={config} imageOptions={imageOptions}/>
                        <ExcelExport stats={stats}/>
                    </div>
                </div>

            </div>
        </MathJax.Provider>
    );
}

export default Results;
