import React from "react";
import { useState, useEffect, useCallback } from "react";
import { useRecoilState } from "recoil";
import { simuParameters, globalSimuResults, baseUrl } from "../../App";
import axios from "axios";
import { Loader, plusSvg } from "../svgs";
import SimuRow from "./SimuRow";
import { GrNodes } from "react-icons/gr";
import { Switch } from '@mui/material';
import DatabaseTable from "./DatabaseTable";
import CustomInput from "../input/CustomInput";

const params = [
  {
    fullName: "Meshcount",
    shortName: "mc",
    unit: "1/Inch",
    range: { lower: 50, upper: 700 },
  },
  {
    fullName: "Meshcount Weft",
    shortName: "mcx",
    unit: "1/Inch",
    range: { lower: 50, upper: 700 },
  },
  {
    fullName: "Meshcount Warp",
    shortName: "mcy",
    unit: "1/Inch",
    range: { lower: 50, upper: 700 },
  },
  {
    fullName: "Wire diameter",
    shortName: "d",
    unit: "µm",
    range: { lower: 5, upper: 50 },
  },
  // {
  //   fullName: "Wire diameter Weft",
  //   shortName: "dx",
  //   unit: "µm",
  //   range: { lower: 5, upper: 50 },
  // },
  // {
  //   fullName: "Wire diameter Warp",
  //   shortName: "dy",
  //   unit: "µm",
  //   range: { lower: 5, upper: 50 },
  // },
  {
    fullName: "Kappa",
    shortName: "k",
    unit: "-",
    range: { lower: 0, upper: 1 },
  },
  {
    fullName: "Kappa Weft",
    shortName: "kx",
    unit: "-",
    range: { lower: 0, upper: 1 },
  },
  {
    fullName: "Kappa Warp",
    shortName: "ky",
    unit: "-",
    range: { lower: 0, upper: 1 },
  },
  {
    fullName: "Theta",
    shortName: "theta",
    unit: "deg",
    range: { lower: 45, upper: 90 },
  },
  {
    fullName: "Phi",
    shortName: "phi",
    unit: "deg",
    range: { lower: 0, upper: 45 },
  },
];


function SimuSection({ imageOptions }) {
  const simuUrl = baseUrl + "/simu/";
  const status_url = baseUrl + "/status/"



  const [, setsimResults] = useRecoilState(globalSimuResults);
  const [simuParams] = useRecoilState(simuParameters);
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);
  const [notUsedParams] = useState(params);
  const [rows, setRows] = useState([]);
  const [status, setStatus] = useState("");
  const [isManual, setIsManual] = useState(false);
  const [manualValues,setManualValues] =useState({"phi" :simuParams.mesh.phi ,"ids" : []})
  const [sweepParams,setSweepParams] = useState({})
  const handleToggle = () => {
    setIsManual(prevState => !prevState);
    setSweepParams({})
    if (isManual)
      setRows([])
  };

  const handleAngleChange = (newValue) => {
      setManualValues(
                    prevState => ({
                        ...prevState,
                        "phi" :newValue})
      );
  };



  const fetchSimulation = async () => {
    console.log("Sending data:", {
      mesh: simuParams.mesh,
      sweepInfo: sweepParams,
      //   sweepIterations: sweepIterations,
    });

    if (!checkForErrors()) {
      setloading(true);
      setStatus( "Uploading file");
      const fileSaveUrl = baseUrl + "/file_save/";
      let layoutInfo = { layers: null, fileUrl: null };
      if (imageOptions.fileType === "dxf" && imageOptions.rawImage
        && imageOptions.layers.length > 0)
        layoutInfo.layers = imageOptions.layers;
      const saveFileData = await saveFileFormData(imageOptions);
      try {
        const fileResponse = await axios.post(fileSaveUrl, saveFileData)
        setStatus( "File uploaded");

        const filedata = await fileResponse.data;
        layoutInfo.fileUrl = filedata.fileUrl;
      } catch (error) {
        console.error("Request failed:", error);
        seterror(error.response.data.detail);
      }
      const jsonData = {
        "layoutInfo":
          layoutInfo,
        "mesh": simuParams.mesh,
        "sweepInfo": sweepParams,
        "manualMode": isManual,
        "manualValues" : manualValues
        //     "sweepIterations": sweepIterations
      };
      try {
        const response = await axios.post(simuUrl, jsonData);
        const jobId = response.data.job_id;
        setStatus(response.data.status);
        const checkStatus = setInterval(async () => {
          try {

            const statusResponse = await axios.get(status_url + jobId + "/");
            if (statusResponse.data.status === 'completed') {
              setsimResults(statusResponse.data.best);
              setloading(false);
              clearInterval(checkStatus);
            } else if (statusResponse.data.status === 'failed') {
              clearInterval(checkStatus);
              setStatus('Optimazation failed.');
              setloading(false);

            }
            else {
              setStatus(statusResponse.data.status)
            }
          } catch (error) {
            console.error('Error processing file:', error);
            setStatus('Error at optimazation');
            clearInterval(checkStatus)
            setloading(false);

          }
        }, 30000);// Check status every
      } catch (error) {
        console.error("Request failed:", error);
        seterror(error.response?.data?.detail);

      }
    } else {
      console.log("found error:", error);
    }
  };

  const saveFileFormData = async (imageOptions) => {
    const formData = new FormData();
    // if (imageOptions.fileType === "image" &&
    //   imageOptions.rawImage && imageOptions.rawImage.startsWith("data:image/")) {
    //   const base64Image = imageOptions.rawImage.split(",")[1];
    //   const byteCharacters = atob(base64Image);
    //   const byteNumbers = new Array(byteCharacters.length)
    //     .fill(0)
    //     .map((_, i) => byteCharacters.charCodeAt(i));
    //   const byteArray = new Uint8Array(byteNumbers);
    //   const blob = new Blob([byteArray], { type: "image/jpeg" });
    //   formData.append("file", blob, "image.jpg");
    // } else
     if (imageOptions.fileType === "dxf" && imageOptions.rawImage && imageOptions.layers.length > 0) {
      const dxfBlob = new Blob([imageOptions.rawImage], { type: "application/dxf" });
      formData.append("file", dxfBlob, "file.dxf");
    } else {
      console.error("No valid file to upload or unsupported file type");
      return;
    }
    return formData;
  }



  const checkForErrors = () => {
    if (hasOverlapParameters()) {
      return true;
    }
    if (!imageOptions || !imageOptions.rawImage) {
      seterror("File is not Uploaded!")
      return true
    }
    if (Object.keys(sweepParams).length === 0 && isManual) {
      seterror("Define at least one optimization parameter!");
      return true;
    }
    if (!isManual)
      {
      //   if (!manualValues['phi']){
      //   seterror("Define screen angle!");
      //   return true;
      // }
      if (manualValues['ids'].length=== 0)
        {
          seterror("Define at least one value from database!");
          return true;
        }

      }
    seterror(false);
    return false;
  };

  const addNewRow = async () => {
    if (rows.length === 0) {
      setRows(rows.concat(0));
    } else {
      setRows(rows.concat(rows[rows.length - 1] + 1));
    }
  };

  const hasOverlapParameters = useCallback(() => {
    const wantedSweepParams = sweepParams;
    let names = [];
    for (const key in wantedSweepParams) {
      if (wantedSweepParams[key]) {
        names.push(wantedSweepParams[key].name);
      }
    }
    //console.log(names);
    const overlaps = ["mc", "d", "k"];
    for (const i in overlaps) {
      let count = 0;
      const overlap = overlaps[i];
      for (const j in names) {
        const name = names[j];
        if (name.includes(overlap)) {
          count = count + 1;
        }
        if (count > 1) {
          seterror("Contradiction in Parameters!");
          console.log("has overlap", overlap);
          return true;
        }
      }
    }
    console.log("no overlap");
    return false;
  }, [sweepParams]);

  useEffect(() => {
      if (error && error === "Contradiction in Parameters!" && !hasOverlapParameters()) {
        seterror(false);
      }
    }
  , [sweepParams, simuParams, hasOverlapParameters, error]);

  return (
    <div className="flex flex-col w-full grid grid-cols-3 gap-y-1 gap-x-2">
  <div className="grid grid-cols-3 col-span-3">
    <div className="colspan-1 grid grid-cols-2 flex items-center">
      <div className="flex items-center">
        <Switch checked={isManual} onChange={handleToggle} disabled={loading ? true : false} />
        <span className="mr-2 font-poppins font-bold">{isManual ? 'Manual' : 'Database'}</span>
      </div>
      <div>
        {!isManual ? (
          <CustomInput
            label="screen angle"
            name="phi"
            range={{ lower: 0, upper: 45 }}
            default={simuParams.mesh.phi}
            unit="deg"
            onChange={(value) => handleAngleChange(value)}
          />
        ) : <></>}
      </div>
    </div>
    <div className="colspan-2"></div>
  </div>
        {isManual ?
          <div>
            <button
              onClick={addNewRow}
              className="group w-full col-start-2 relative disabled:cursor-not-allowed bg-transparent hover:bg-[#005B7F] text-[#005B7F] font-semibold hover:text-white py-2 px-8 border-2 border-[#005B7F] hover:border-transparent rounded-xl"
            >
              <div className="flex items-center justify-center">
                <div className="inset-y-0 mr-4 w-8 h-8">{plusSvg}</div>
                Parameter
              </div>
            </button></div> : null}
        <div>
          <button
            className={
              error
                ? "cursor-not-allowed w-full group col-start-2 relative bg-transparent hover:bg-red-600 text-red-600 font-semibold hover:text-white py-2 px-8 border-2 border-red-600 hover:border-transparent rounded-xl"
                : "disabled:cursor-not-allowed w-full group col-start-2 relative bg-transparent hover:bg-[#005B7F] text-[#005B7F] font-semibold hover:text-white py-2 px-8 border-2 border-[#005B7F] hover:border-transparent rounded-xl"
            }
            onClick={fetchSimulation}
            disabled={loading ? true : false}
          >
            <div className="flex items-center justify-center">
              {loading ? (
                <>
                  <GrNodes size={32} className="mr-4" />
                  {status}
                </>
              ) : (
                <>
                  <GrNodes size={32} className="mr-4" />
                  Simulate!
                </>
              )}
            </div>
            <div className="absolute right-0 inset-y-0 m-2">
              {loading ? <Loader />
                : null}
            </div>
          </button>
          <p
            className={
              error
                ? "text-red-500 row-start-2 col-start-3 text-xs italic"
                : "hidden text-red-500 row-start-2 col-start-3 text-xs italic"
            }
          >
            {error}
          </p>
        </div>
            
        {!isManual? (
          <div className="col-span-2 drawing">
            <DatabaseTable manualValues={manualValues} setManualValues={setManualValues}></DatabaseTable>
          </div>):<div></div>}
      <div className="col-span-3 py-5">
      {rows.map((id) => {
        return (
          <SimuRow
            key={id}
            id={id}
            notUsedParams={notUsedParams}
            sweepParams={sweepParams}
            setSweepParams={setSweepParams}
            setRows={setRows}
            rows={rows}
          />
        );
      })}
      </div>
    </div>
  );
}

export default SimuSection;
