import React,{useState,useRef,useEffect} from "react";
import { useRecoilState } from "recoil";
import { dropDownSvg} from "../svgs";

function SimuDropDown(props) {
  const [open, setopen] = useState(false);
  const ref = useRef();
  useOutsideAlerter(ref);

  const changeSimuParam = async (param) => {
    props.setcurrentSelect(param);
    props.setSweepParams({
        ...props.sweepParams,
        [props.id]: {
          name: param.shortName,
          lower: param.range.lower,
          upper: param.range.upper,
      },
    });
    setopen(!open);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setopen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }


  return (
    <div ref={ref} className="relative w-full">
    <button
      onClick={() => setopen(!open)}
      className="flex w-full justify-between content-center hover:border-[#005B7F] text-left bg-white text-gray-700 rounded-lg border-2 py-2 px-2 leading-tight focus:outline-none"
    >
      {props.currentSelect.fullName}
      {dropDownSvg}
    </button>
    {open ? (
        <ul style={{zIndex:1}}className="absolute w-full bg-white border-2 rounded-lg">
          {props.notUsedParams.map((param, i) => {
            return (
              <li key={i}>
                <button
                  className="w-full text-left hover:bg-[#005B7F] hover:text-white py-2 px-2 "
                  onClick={() => changeSimuParam(param)}
                >
                  {param.fullName}
                </button>
              </li>
            );
          })}
        </ul>
    ) : <></>}
  </div>
  );
}

export default SimuDropDown;
