import React,{useState} from 'react';
import '../../styles/button.css'; // Import the CSS file
import { formatResultKey,formatResultValue} from "../../utils/resultsutils";
import {baseUrl} from '../../App'


function ExcelExport({stats}){
  const url = baseUrl +"/excel-export/?";
  //const url = "http://localhost:8000/api/excel-export/?"


  const [formattedStats] = useState(() => {
          const formatted = {};
          Object.entries(stats).forEach(([key, value]) => {
            const formattedKey = formatResultKey(key)
            if (typeof formattedKey == 'string') {
              formatted[formattedKey] = formatResultValue(key, value).toString();
          }
          });
          return formatted;
      });

  return (    <a
              className="download-button"
              style={{display:"block", width:"100%"}}
              target={"_blank"}  rel="noopener noreferrer"
              href={url+new URLSearchParams(formattedStats).toString()} download>Download Excel</a>

  );
}


export default ExcelExport;