import React from "react";
import CustomInput from "./CustomInput";
import { useRecoilState } from "recoil";
import { simuParameters } from "../../App";
import { useState } from "react";

function MeshInput() {
  const [simuParams, setsimuParams] = useRecoilState(simuParameters);
  const [error, seterror] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      2.54e4 / parseFloat(event.target.mcx.value) <
        parseFloat(event.target.dx.value)
        //||
    //   2.54e4 / parseFloat(event.target.mcy.value) <
    //     parseFloat(event.target.dy.value)
     ) {
      seterror("Wire Diameter too big for the Meshcount");
    } 
    else {
      seterror(null);
      setsimuParams({
        ...simuParams,
        mesh: {
        //  size: parseFloat(event.target.size.value),
          mcx: parseFloat(event.target.mcx.value),
          mcy: parseFloat(event.target.mcy.value),
          dx: parseFloat(event.target.dx.value),
        //  dy: parseFloat(event.target.dy.value),
          // kx: parseFloat(event.target.kx.value),
          // ky: parseFloat(event.target.ky.value),
          // theta: parseFloat(event.target.theta.value),
          phi: parseFloat(event.target.phi.value),
        },
      });
    }
  };

  return (
    <>
      <form action="" className="flex flex-col w-full" onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-x-4">
          <CustomInput
            label="meshcount weft"
            name="mcx"
            range={{ lower: 50, upper: 700 }}
            default={simuParams.mesh.mcx}
            unit="1/Inch"
          />
          <CustomInput
            label="meshcount warp"
            name="mcy"
            range={{ lower: 50, upper: 700 }}
            default={simuParams.mesh.mcy}
            unit="1/Inch"
          />
          <CustomInput
            label="wire diameter"
            name="dx"
            range={{ lower: 5, upper: 50 }}
            default={simuParams.mesh.dx}
            unit="µm"
          />
          {/*<CustomInput*/}
          {/*  label="wire diameter warp"*/}
          {/*  name="dy"*/}
          {/*  range={{ lower: 5, upper: 50 }}*/}
          {/*  default={simuParams.mesh.dy}*/}
          {/*  unit="µm"*/}
          {/*/>*/}
          <CustomInput
            label="screen angle"
            name="phi"
            range={{ lower: 0, upper: 45 }}
            default={simuParams.mesh.phi}
            unit="deg"
          />
          {/* <CustomInput
            label="layout size"
            name="size"
            range={{ lower: 100, upper: 10*25400 }}
            default={simuParams.mesh.size}
            unit="µm"
          /> */}
        </div>
        {/* <div className="w-48 mx-auto">
          <CustomInput
            label="layout size"
            name="size"
            range={{ lower: 100, upper: 5000 }}
            default={simuParams.mesh.size}
            unit="µm"
          />
        </div> */}
        <input
          type="submit"
          value="Generate Mesh"
          className={
            error
              ? "cursor-not-allowed bg-transparent hover:bg-red-600 text-red-600 font-semibold hover:text-white py-2 px-4 border-2 border-red-600 hover:border-transparent rounded-xl"
              : "layout-button "
          }
        />
      </form>
      <p
        className={
          error
            ? "text-red-500 row-start-2 col-start-3 text-xs italic mt-4"
            : "hidden text-red-500 row-start-2 col-start-3 text-xs italic mt-4"
        }
      >
        {error}
      </p>
    </>
  );
}

export default MeshInput;
