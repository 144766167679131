import React from "react";

function Headline(props) {
  return (
    <div id={props.headline} className="my-8">
      <h2 className="text-5xl leading-normal border-b-4" style={{ borderColor: 'rgb(23, 156, 125)' }}>
        {props.headline}
      </h2>
      <p className=" leading-loose font-poppins font-bold text-align-top">
        {props.description}
      </p>
    </div>
  );
}

export default Headline;
