export function calculateDimensions(divRef) {
    const current = divRef.current;
    const clientWidth = current.clientWidth;
  //  const clientHeight = current.clientHeight;
  
    const computedStyle = getComputedStyle(current);
    const marginLeft = parseFloat(computedStyle.marginLeft);
    const marginRight = parseFloat(computedStyle.marginRight);
  //  const marginTop = parseFloat(computedStyle.marginTop);
   // const marginBottom = parseFloat(computedStyle.marginBottom);
  
    const width = clientWidth - marginLeft - marginRight;
    const height = width
    return { width, height };
  }