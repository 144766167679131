import React, {useRef, useState } from "react";
import DxfParser from 'dxf-parser';
import '../styles/popup.css';
import {calculateDimensions} from "../utils/shapeutils"
import { Checkbox ,FormControlLabel} from "@mui/material";
import Typography from '@mui/material/Typography';
function ImageSection({ imageOptions, setimageOptions }) {
  const fileRef = useRef();
  const canvasRef = useRef(null);
  const containerRef = useRef(null);

  const [showLayerPopup, setShowLayerPopup] = useState(false);
  const [dxfLayers, setDxfLayers] = useState([]);
  const [selectedLayers, setSelectedLayers] = useState({});

  const handleChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();

      if (fileExtension === "dxf") {
        const reader = new FileReader();
        reader.onload = () => {
          const parser = new DxfParser();
          const dxf = parser.parseSync(reader.result);
          const layers = [...new Set(dxf.entities.map(entity => entity.layer))];
          setDxfLayers(layers);
          setShowLayerPopup(true);
          setimageOptions({ ...imageOptions, rawImage: reader.result, fileType: "dxf" });
        };
        reader.readAsText(selectedFile);
      } else {
        console.error("Unsupported file type");
      }
      fileRef.current.value = null;
    }
  };

  const renderDXF = (dxf) => {
    let { minX, minY, maxX, maxY } = getDimensions(dxf);
    const dxfWidth = maxX - minX;
    const dxfHeight = maxY - minY;

    const canvas = canvasRef.current
    const {width,}  = calculateDimensions(containerRef);
    const height = width * (dxfHeight / dxfWidth);
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.strokeStyle = "black";
    ctx.lineWidth = 1;

    const scale = Math.min(canvas.width / dxfWidth, canvas.height / dxfHeight);
    const offsetX = (canvas.width - dxfWidth * scale) / 2 - minX * scale;
    const offsetY = (canvas.height - dxfHeight * scale) / 2 - minY * scale;

    dxf.entities.forEach(entity => {
      if (selectedLayers[entity.layer]) {
        ctx.save();
        ctx.scale(scale, scale);
        ctx.translate(offsetX / scale, offsetY / scale);

        switch (entity.type) {
          case 'LINE':
            ctx.beginPath();
            ctx.moveTo(entity.vertices[0].x, entity.vertices[0].y);
            ctx.lineTo(entity.vertices[1].x, entity.vertices[1].y);
            ctx.stroke();
            break;
          case 'LWPOLYLINE':
          case 'POLYLINE':
            ctx.beginPath();
            entity.vertices.forEach((vertex, index) => {
              if (index === 0) {
                ctx.moveTo(vertex.x, vertex.y);
              } else {
                ctx.lineTo(vertex.x, vertex.y);
              }
            });
            if (entity.closed) {
              ctx.closePath();
            }
            ctx.stroke();
            break;
          case 'CIRCLE':
            ctx.beginPath();
            ctx.arc(entity.center.x, entity.center.y, entity.radius, 0, 2 * Math.PI);
            ctx.stroke();
            break;
          case 'ARC':
            ctx.beginPath();
            ctx.arc(entity.center.x, entity.center.y, entity.radius, (entity.startAngle * Math.PI) / 180, (entity.endAngle * Math.PI) / 180);
            ctx.stroke();
            break;
          case 'SPLINE': {
            ctx.beginPath();
            const controlPoints = entity.controlPoints.map(point => ({ x: point.x, y: point.y }));
            ctx.moveTo(controlPoints[0].x, controlPoints[0].y);
            for (let i = 1; i < controlPoints.length - 2; i++) {
              const cp1 = controlPoints[i];
              const cp2 = controlPoints[i + 1];
              ctx.quadraticCurveTo(cp1.x, cp1.y, (cp1.x + cp2.x) / 2, (cp1.y + cp2.y) / 2);
            }
            ctx.quadraticCurveTo(controlPoints[controlPoints.length - 2].x, controlPoints[controlPoints.length - 2].y, controlPoints[controlPoints.length - 1].x, controlPoints[controlPoints.length - 1].y);
            ctx.stroke();
            break;
          }
          default:
            console.warn(`Unsupported entity type: ${entity.type}`);
            break;
        }

        ctx.restore();
      }
    });

  };

  function getDimensions(dxf) {
    let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;

    dxf.entities.forEach(entity => {
      if (selectedLayers[entity.layer]) {
        switch (entity.type) {
          case 'LWPOLYLINE':
          case 'POLYLINE':
            case 'LINE':

            entity.vertices.forEach(vertex => {
              minX = Math.min(minX, vertex.x);
              minY = Math.min(minY, vertex.y);
              maxX = Math.max(maxX, vertex.x);
              maxY = Math.max(maxY, vertex.y);
            });
            break;
          case 'CIRCLE':
            minX = Math.min(minX, entity.center.x - entity.radius);
            minY = Math.min(minY, entity.center.y - entity.radius);
            maxX = Math.max(maxX, entity.center.x + entity.radius);
            maxY = Math.max(maxY, entity.center.y + entity.radius);
            break;
          case 'ARC': {
            const startAngleRad = (entity.startAngle * Math.PI) / 180;
            const endAngleRad = (entity.endAngle * Math.PI) / 180;
            minX = Math.min(minX, entity.center.x + entity.radius * Math.cos(startAngleRad));
            minY = Math.min(minY, entity.center.y + entity.radius * Math.sin(startAngleRad));
            maxX = Math.max(maxX, entity.center.x + entity.radius * Math.cos(endAngleRad));
            maxY = Math.max(maxY, entity.center.y + entity.radius * Math.sin(endAngleRad));
            break;
          }
          case 'SPLINE':
            entity.controlPoints.forEach(point => {
              minX = Math.min(minX, point.x);
              minY = Math.min(minY, point.y);
              maxX = Math.max(maxX, point.x);
              maxY = Math.max(maxY, point.y);
            });
            break;
          default:
            console.warn(`Unsupported entity type: ${entity.type}`);
            break;
        }
      }
    });
    return { minX, minY, maxX, maxY };
  }

  const handleLayerSelection = (event) => {
    const { name, checked } = event.target;
    setSelectedLayers({ ...selectedLayers, [name]: checked });
  };

  const closeLayerPopup = () => {
    setShowLayerPopup(false);
  };

  const saveLayerSelection = () => {
    const parser = new DxfParser();
    const dxf = parser.parseSync(imageOptions.rawImage);
    renderDXF(dxf);
    setShowLayerPopup(false);
    setimageOptions({ ...imageOptions, rawImage: imageOptions.rawImage, fileType: "dxf", layers: Object.keys(selectedLayers).filter(key => selectedLayers[key] === true) });
  };

  return (
<>
<div className="flex flex-col w-full col-span-1">
          <button
    className="layout-button w-full"
    onClick={() => fileRef.current.click()}
          >
            <input
              id="upload"
              name="upload"
              type="file"
              ref={fileRef}
              hidden
              onChange={handleChange}
              accept=".dxf"/>Upload File &#40;dxf&#41;
          </button>
          
          {imageOptions.fileType === "dxf" && ( 

            <button
    className="layout-button w-full"
    onClick={() => setShowLayerPopup(true)}
            >
              Layer Selection
            </button>
          )}
        </div>
        <div ref={containerRef} className="drawing image col-span-2">
          {imageOptions.fileType === "dxf" && (
            <canvas ref={canvasRef}
              id="canvas"
              style={{ borderRadius: '15px', }}
            ></canvas>)}
        </div>
      
        {showLayerPopup && (
  <div className="col-span-3 popup-overlay">
    <div className="popup" style={{ width: '22%' }}>
      <h2 className="text-xl font-bold mb-4">Layer Selection</h2>
      <div className="mb-2">
        <FormControlLabel
         label={<Typography sx={{ fontWeight: 'bold',fontFamily: 'Poppins' }}>Select All</Typography>}
        control={
          <Checkbox sx={{ py:0.25,pr:0 }}
            checked={dxfLayers.length > 0 && dxfLayers.every(layer => !!selectedLayers[layer])}
            indeterminate={dxfLayers.length>Object.keys(selectedLayers).filter(key => selectedLayers[key]).length &&Object.keys(selectedLayers).filter(key => selectedLayers[key]).length>0}
            onChange={(e) => {
              const isChecked = e.target.checked;
              const newSelectedLayers = {};
              dxfLayers.forEach(layer => {
                newSelectedLayers[layer] = isChecked;
              });
              setSelectedLayers(newSelectedLayers);
            }}
          />} />
      </div>
      <hr className="my-2" />
      <div className="mb-2" style={{ maxHeight: '32%', overflowY: 'auto' }}>
        {dxfLayers.map((layer, index) => (
          <div key={'div-'+index} >
                    <FormControlLabel 
                             label={<Typography sx={{fontFamily: 'Poppins'}}>{layer}</Typography>}
        control={
            <Checkbox sx={{py:0.25,pr:0}}
                name={layer}
                checked={!!selectedLayers[layer]}
                onChange={handleLayerSelection}
              />}/>
          </div>
        ))}
      </div>
      <div className="flex justify-end">
        <button
          className="bg-transparent hover:bg-gray-400 text-gray-700 font-semibold py-2 px-4 border border-gray-400 rounded mr-2"
          onClick={closeLayerPopup}
        >
          Cancel
        </button>
        <button
          className="bg-[#179C7D] hover:bg-[#148C70] text-white font-bold py-2 px-4 rounded"
          onClick={saveLayerSelection}
        >
          Ok
        </button>
      </div>
    </div>
  </div>
)}</>

  );
}

export default ImageSection;
